<template>
    <b-modal scroll="keep"
             has-modal-card
             v-bind:active="active"
             v-on:close="$emit('update:active', false)">
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ editing ? "Edit" : "Add" }} a SKU
            </p>
        </header>
        <section class="modal-card-body">
            <div class="field">
                <label class="label">Treatment SKU Name (*required)</label>
                <b-switch v-model="enableTcToSc">
                    <strong>Auto Traditional-to-Simplified-Chinese conversion</strong>
                </b-switch>
                <b-field style="margin-top: .75rem;">
                    <p class="control" style="min-width: 90px; ">
                        <span class="button is-static" style="width: 100%; ">English</span>
                    </p>
                    <b-input type="text"
                             expanded
                             placeholder=""
                             v-model.trim="name['en']">
                    </b-input>
                </b-field>
                <b-field>
                    <p class="control">
                        <span class="button is-static">繁體中文</span>
                    </p>
                    <b-input type="text"
                             expanded
                             placeholder=""
                             v-model.trim="name['zh-hk']"
                             v-on:input="convertTcToSc">
                    </b-input>
                </b-field>
                <b-field>
                    <p class="control">
                        <span class="button is-static">簡體中文</span>
                    </p>
                    <b-input type="text"
                             expanded
                             placeholder=""
                             v-bind:disabled="enableTcToSc"
                             v-model.trim="name['zh-cn']">
                    </b-input>
                </b-field>
            </div>

            <div class="label">Treatment SKU Duration</div>
            <b-field>
                <vue-timepicker
                    v-model="duration">
                </vue-timepicker>
            </b-field>

            <div class="label">Treatment SKU Price (*required)</div>
            <b-field>
                <b-select placeholder="Currency"
                          v-model="currency">
                    <option value="hkd">HK$</option>
                </b-select>
                <b-input type="number" placeholder="0.00"
                         v-model="price"></b-input>
            </b-field>

            <div class="label">Treatment SKU Cost (Optional)</div>
            <b-field>
                <b-select placeholder="Currency"
                          v-model="costCurrency">
                    <option value="hkd">$</option>
                </b-select>
                <b-input type="number" placeholder="0.00"
                         v-model="cost">
                </b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="closeModal()">
                Cancel
            </button>
            <button class="button is-primary"
                    v-bind:disabled="!allowSave"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="save">
                Save
            </button>
        </footer>
    </div>
    </b-modal>
</template>

<script>
    import VueTimepicker from 'vuejs-timepicker'

    const opencc = require('node-opencc')
    export default {
        name: 'ModalSetSku',
        components: {
            VueTimepicker
        },
        props: {
            'active': {
                type: Boolean,
                default: false
            },

            'sku': {
                type: Object,
                required: true
            },

            'editing': {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                enableTcToSc: true,
                mode: 'add',

                name: {
                    'en': '',
                    'zh-hk': '',
                    'zh-cn': ''
                },
                currency: 'hkd',
                price: null,
                duration: {
                    HH: '00',
                    mm: '00'
                },

                costCurrency: 'hkd',
                cost: null
            }
        },
        computed: {
            allowSave() {
                return this.name['en'] &&
                    this.name['zh-hk'] &&
                    this.name['zh-cn'] &&
                    this.currency &&
                    (parseInt(this.duration.HH) > 0 || parseInt(this.duration.mm) > 0) &&
                    this.price
            }
        },
        methods: {
            closeModal() {
                this.$emit('update:active', false)
            },

            convertTcToSc(val) {
                if (this.enableTcToSc) {
                    this.name['zh-cn'] = opencc.traditionalToSimplified(val)
                }
            },

            save() {
                let temp = {
                    'name': this.name
                }

                temp[this.currency] = this.price

                if (this.cost) {
                    temp['cost'] = {}
                    temp['cost'][this.costCurrency] = this.cost
                }

                if (this.duration) {
                    temp['duration'] = this.duration
                }

                this.$emit('update:sku', temp)
                this.$emit('change')
                this.closeModal()
            }
        },
        watch: {
            'sku'(newVal) {
                if (newVal) {
                    this.name = {
                        'en': newVal.name['en'],
                        'zh-hk': newVal.name['zh-hk'],
                        'zh-cn': newVal.name['zh-cn']
                    }
                    this.price = newVal.hkd
                    this.duration = {
                        HH: newVal.duration['HH'],
                        mm: newVal.duration['mm']
                    }
                    if (newVal.cost) {
                        this.cost = newVal.cost.hkd
                    }
                }
            }
        },
        mounted() {
            if (this.sku) {
                this.name = {
                    'en': this.sku.name['en'],
                    'zh-hk': this.sku.name['zh-hk'],
                    'zh-cn': this.sku.name['zh-cn']
                }
                this.price = this.sku.hkd
                this.duration = {
                    HH: this.sku.duration['HH'],
                    mm: this.sku.duration['mm']
                }
                if (this.sku.cost) {
                    this.cost = this.sku.cost.hkd
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-foot {
        justify-content: flex-end;
    }
</style>
