<template>
    <div class="skus">
        <h2 class="text-center text-md-left">
            Treatment SKUs <small>(Current has {{ skus.length }})</small>
        </h2>
        <div class="sku-wrapper">
            <div class="card sku add"
                 v-on:click="isAddSkuModalActive = true">
                <div class="card-body">
                    <h3 class="text-center">
                        <font-awesome-icon icon="plus"></font-awesome-icon>
                    </h3>
                    <p class="text-center m-0">Add a SKU?</p>
                </div>
            </div>

            <sku-card v-for="(skuItem, index) in skus"
                      v-bind:key="index"
                      v-bind:deletable="skus.length > 1"
                      v-bind:lang="lang"
                      v-bind:sku="skuItem"
                      v-on:edit="editSku(index, $event)"
                      v-on:remove="removeSku(index)">
            </sku-card>
        </div>

        <modal-set-sku v-bind:sku.sync="newSku"
                       v-bind:active.sync="isAddSkuModalActive"
                       v-on:change="addSku">
        </modal-set-sku>
    </div>
</template>

<script>
    import ModalSetSku from '../modals/ModalSetSku'
    import SkuCard from './SkuCard'

    export default {
        name: 'TreatmentSkus',
        components: {
            SkuCard,
            ModalSetSku
        },
        props: {
            'lang': {
                type: String,
                required: true
            },

            'skus': {
                'type': Array,
                'required': true
            }
        },
        data() {
            return {
                isAddSkuModalActive: false,
                newSku: {
                    name: {
                        'zh-hk': '',
                        'zh-cn': '',
                        'en': ''
                    },
                    hkd: '',
                    duration: {
                        HH: '',
                        mm: ''
                    }
                },

                skusInComponent: []
            }
        },
        computed: {},
        methods: {
            addSku() {
                this.skus.push(Object.assign({}, this.newSku))
                this.newSku = {
                    name: {
                        'zh-hk': '',
                        'zh-cn': '',
                        'en': ''
                    },
                    hkd: '',
                    duration: {
                        HH: '',
                        mm: ''
                    }
                }
                this.$emit('update:skus', this.skus)
            },

            editSku(index, skuItem) {
                this.skus.splice(index, 1, skuItem)
                this.$emit('update:skus', this.skus)
            },

            removeSku(targetIndex) {
                this.skus.splice(targetIndex, 1)
                this.$emit('update:skus', this.skus)
            }
        }
    }
</script>

<style lang="scss" scoped>
    $theme-colors: (
        "dark": rgba(113, 113, 113, 1)
    );
    @import "~bootstrap/scss/bootstrap";

    .sku-wrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        background-color: rgba(215, 215, 215, 1);
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 10px;
        border-radius: 4px;

        & > .sku {
            margin-right: 15px;
            min-width: 300px;
            max-width: 350px;

            cursor: pointer;

            &:first-child {
                margin-left: 15px;
            }

            &.add {
                & > .card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

</style>
