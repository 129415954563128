<template>
    <section class="treatment-details">
        <tool-bar v-bind:lang.sync="lang">
            {{ mode }} a treatment
            <template v-slot:buttons>
                <div class="level-item"
                     v-if="mode === 'Create'">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
                <div class="level-item"
                     v-if="mode === 'Update'">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="update">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Update</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="banner position-relative d-flex">
            <button class="button edit"
                    v-on:click="setCover"
                    v-on:mouseover="revealImageElement"
                    v-on:mouseout="revealImageElement">
                <span class="icon is-small">
                    <font-awesome-icon icon="image"></font-awesome-icon>
                </span>
                <span>Change Cover</span>
            </button>

            <section class="categories">
                <b-select placeholder="Treatment Series"
                          v-model="series">
                    <option v-for="{ _id, title, } in treatmentSeries"
                            v-bind:key="_id"
                            v-bind:value="_id">
                        {{ title[lang] }}
                    </option>
                </b-select>
                <div class="separator"> /</div>
                <b-select placeholder="Treatment Category"
                          v-model="category">
                    <option v-for="{ _id, name, } in categories"
                            v-bind:value="_id"
                            v-bind:key="_id">
                        {{ name[lang] }}
                    </option>
                </b-select>
            </section>

            <section class="treatment-mode">
                <div class="field has-addons">
                    <p class="control">
                        <a class="button"
                           v-bind:class="{ 'is-selected': withImage, 'is-dark': withImage }"
                           v-on:click="withImage = true">
                            <span class="icon is-small">
                               <font-awesome-icon icon="image"></font-awesome-icon>
                            </span>
                            <span>With Image</span>
                        </a>
                    </p>
                    <p class="control">
                        <a class="button"
                           v-bind:class="{ 'is-selected': !withImage, 'is-dark': !withImage }"
                           v-on:click="withImage = false">
                            <span class="icon is-small">
                                <font-awesome-icon v-bind:icon="['fal', 'image']"></font-awesome-icon>
                            </span>
                            <span>Without Image</span>
                        </a>
                    </p>
                </div>
            </section>

            <background-filter color="transparent"
                               v-bind:src="cover">
            </background-filter>

            <div class="container pt-5">
                <div class="row h-100">
                    <div class="d-flex flex-column justify-content-center"
                         v-bind:class="{ 'col-md-7': withImage, 'col-md-12': !withImage }">
                        <medium-editor class="text-center text-md-left mb-4 display-4 editable"
                                       custom-tag="h1"
                                       v-bind:style="{ 'color': titleColor.rgba ? `rgba(${ titleColor.rgba.r }, ${ titleColor.rgba.g }, ${ titleColor.rgba.b }, ${ titleColor.rgba.a }) !important` : `rgba(${ titleColor.r }, ${ titleColor.g }, ${ titleColor.b }, ${ titleColor.a }) !important` }"
                                       v-bind:text="title[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Title', } }, options)"
                                       v-on:edit="processTitleEditOperation">
                        </medium-editor>

                        <medium-editor class="text-center text-md-left mb-4 lead editable"
                                       custom-tag="p"
                                       v-bind:style="{ 'color': titleColor.rgba ? `rgba(${ titleColor.rgba.r }, ${ titleColor.rgba.g }, ${ titleColor.rgba.b }, ${ titleColor.rgba.a }) !important` : `rgba(${ titleColor.r }, ${ titleColor.g }, ${ titleColor.b }, ${ titleColor.a }) !important` }"
                                       v-bind:text="subtitle[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Subtitle', } }, options)"
                                       v-on:edit="processSubtitleEditOperation">
                        </medium-editor>

                        <div class="position-relative mb-4">
                            <button class="button button-color-picker"
                                    v-on:click="isTitleColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set title color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isTitleColorModalActive }"
                                          v-model="titleColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetTitleColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>
                        </div>

                        <div class="d-flex align-items-baseline justify-content-center justify-content-md-start">
                            <span class="mr-3"
                                  v-bind:style="{ 'color': shareColor.rgba ? `rgba(${ shareColor.rgba.r }, ${ shareColor.rgba.g }, ${ shareColor.rgba.b }, ${ shareColor.rgba.a }) !important` : `rgba(${ shareColor.r }, ${ shareColor.g }, ${ shareColor.b }, ${ shareColor.a }) !important` }">
                                {{ translations.shareTo[lang] }}
                            </span>
                            <div class="social-network"
                                 v-bind:style="{ 'color': shareColor.rgba ? `rgba(${ shareColor.rgba.r }, ${ shareColor.rgba.g }, ${ shareColor.rgba.b }, ${ shareColor.rgba.a }) !important` : `rgba(${ shareColor.r }, ${ shareColor.g }, ${ shareColor.b }, ${ shareColor.a }) !important` }">
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'facebook-square']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'line']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'twitter']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'weibo']">
                                </font-awesome-icon>
                                <font-awesome-icon class="mr-3"
                                                   v-bind:icon="['fab', 'whatsapp']">
                                </font-awesome-icon>
                            </div>
                        </div>

                        <div class="position-relative mb-4">
                            <button class="button button-color-picker"
                                    v-on:click="isShareButtonsColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set share button color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isShareButtonsColorModalActive }"
                                          v-model="shareColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetShareButtonsColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>
                        </div>
                    </div>
                    <div class="col-md-5 pr-md-4 pb-4 pb-md-0"
                         v-if="withImage">
                        <div class="treatment-image h-100 position-relative text-center">
                            <button class="button edit"
                                    v-on:click="setImage"
                                    v-on:mouseover="revealImageElement"
                                    v-on:mouseout="revealImageElement">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="image"></font-awesome-icon>
                                </span>
                                <span>Change Image</span>
                            </button>
                            <img class="img-responsive"
                                 v-bind:src="images[0]">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="description pt-5 mb-4">
            <div class="container">
                <div class="level">
                    <div class="level-left"></div>
                    <div class="level-right">
                        <div class="level-item">
                            <button class="button"
                                    v-on:click="addDescriptionBlock">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                </span>
                                <span>Add Description Block</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-5"
                     v-for="(description, index) in descriptions"
                     v-bind:key="index">
                    <medium-editor class="text-center text-md-left editable"
                                   custom-tag="h2"
                                   v-bind:text="description.title[lang]"
                                   v-bind:options="Object.assign({ placeholder: { text: 'Description Title', } }, options)"
                                   v-on:edit="processDescriptionTitleEditOperation">
                    </medium-editor>

                    <medium-editor class="text-center text-md-left editable"
                                   custom-tag="p"
                                   v-bind:text="description.content[lang]"
                                   v-bind:options="Object.assign({ placeholder: { text: 'Description Content', } }, options)"
                                   v-on:edit="processDescriptionContentEditOperation">
                    </medium-editor>

                    <div class="level" style="padding-top: 20px;">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <button class="button is-danger is-outlined"
                                        v-on:mouseover="revealDescriptionBlockElement"
                                        v-on:mouseout="revealDescriptionBlockElement"
                                        v-on:click="removeDescriptionBlock(index)">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                </span>
                                    <span>Remove Description Block</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form pb-5 mb-4">
            <div class="container">
                <treatment-skus class="mb-5"
                                v-bind:lang="lang"
                                v-bind:skus.sync="prices">
                </treatment-skus>

                <div class="available-branches mb-5">
                    <h2 class="text-center text-md-left">
                        Available Branches <small>(Currently available in {{ availableBranches.length }})</small>
                    </h2>
                    <b-table checkable
                             v-bind:data="branches"
                             v-bind:checked-rows.sync="availableBranches"
                             v-bind:custom-is-checked="checkRow">
                        <template>
                            <b-table-column v-slot="{ row }"
                                            field="region"
                                            label="Region"
                                            centered>
                                {{ row.region }}
                            </b-table-column>

                            <b-table-column v-slot="{ row }"
                                            field="type"
                                            label="Type"
                                            centered>
                                {{ locationTypeMap[row.type][lang] }}
                            </b-table-column>

                            <b-table-column v-slot="{ row }"
                                            field="name"
                                            label="Name"
                                            centered>
                                {{ row.name ? row.name[lang] : locationTypeMap[row.type][lang] }}
                            </b-table-column>

                            <b-table-column v-slot="{ row }"
                                            field="address"
                                            label="Address">
                                {{ row.address[lang] }}
                            </b-table-column>
                        </template>
                        <template slot="bottom-left">
                            <b>Available in </b>: {{ availableBranches.length }} branch(es)
                        </template>
                    </b-table>
                </div>

                <div class="mb-5">
                    <h2 class="text-center text-md-left">
                        Visibility
                    </h2>
                    <div class="field">
                        <b-switch class="d-inline-flex"
                                  type="is-success"
                                  v-model="hidden">
                            <span v-if="hidden">
                                Treatment Hidden
                            </span>
                            <span v-else>
                                Treatment Visible
                            </span>
                        </b-switch>
                    </div>
                </div>

                <div class="available-branches mb-5">
                    <h2 class="text-center text-md-left">
                        Time Slot Management
                    </h2>
                    <full-calendar ref="slotCalendar"
                                   v-bind:config="fullCalendarBaseConfig"
                                   v-bind:events="events"
                                   v-on:day-click="slotSelected">
                    </full-calendar>
                </div>
            </div>
        </div>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.cover">
            <modal-set-image allow-video
                             v-bind:file.sync="coverFile"
                             v-bind:video.sync="videoCover">
                Configure Treatment Cover
            </modal-set-image>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.image">
            <modal-set-image v-bind:file.sync="imageFiles"
                             multiple="false">
                Configure Product Image
            </modal-set-image>
        </b-modal>
    </section>
</template>

<script>
import TreatmentSkus from "../../components/TreatmentDetails/TreatmentSkus";
import tinycolor from "tinycolor2";

import editor from "vue2-medium-editor";
import BackgroundFilter from "../../components/BackgroundFilter";
import ModalSetImage from "../../components/modals/ModalSetImage";
import ToolBar from "../../components/layouts/ToolBar";

import Chrome from "../../components/vue-color/components/Chrome";

const opencc = require("node-opencc");

export default {
    name: "TreatmentDetails",
    components: {
        TreatmentSkus,
        ToolBar,
        ModalSetImage,
        BackgroundFilter,
        "medium-editor": editor,
        "color-picker": Chrome
    },
    props: {
        treatmentId: {
            type: String
        }
    },
    data() {
        return {
            loading: false,
            isTitleColorModalActive: false,
            isShareButtonsColorModalActive: false,
            edited: false,
            withImage: false,

            lang: "en",
            translations: {
                "shareTo": {
                    "en": "Share to: ",
                    "zh-hk": "分享至：",
                    "zh-cn": "分享至："
                }
            },
            locationTypeMap: {
                "salon": {
                    "zh-hk": "Marier美容中心",
                    "zh-cn": "Marier美容中心",
                    "en": "Marier Salon"
                },
                "counter": {
                    "zh-hk": "Marier專門店/專櫃",
                    "zh-cn": "Marier专门店/专柜",
                    "en": "Marier Counter"
                }
            },

            events: [],

            fullCalendarBaseConfig: {
                schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
                defaultView: "timelineDay",
                header: {
                    left: "prev,next",
                    right: "timelineDay"
                },
                resourceLabelText: "Branch",
                resources: []
            },

            mode: "Create",
            options: {
                toolbar: {
                    buttons: ["bold", "italic", "underline", "strikethrough"]
                }
            },

            isSkuModalActive: false,
            skuEditTargetIndex: null,

            isImageModalsActive: {
                cover: false,
                image: false
            },

            _id: '',

            hidden: false,

            title: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            subtitle: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            titleColor: {
                r: 113,
                g: 113,
                b: 113,
                a: 1
            },

            shareColor: {
                r: 113,
                g: 113,
                b: 113,
                a: 1
            },

            category: "",
            series: "",

            images: [],
            imageFiles: [],
            cover: '',
            coverFile: null,
            videoCover: {},

            descriptions: [
                {
                    title: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    content: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    }
                }
            ],

            availableBranches: [],
            prices: [],
            duration: null,
            cost: null,
            costCurrency: "hkd"
        }
    },
    computed: {
        shop () {
            return this.$store.state.shop;
        },

        salon () {
            return this.$store.state.salon.salon;
        },

        branches () {
            return this.shop ? this.shop.branches : [];
        },

        categories () {
            return this.salon ? this.salon.categories : [];
        },

        treatments () {
            return this.salon ? this.salon.treatments : [];
        },

        treatmentSeries() {
            return this.salon ? this.salon.series : [];
        },

        descriptionsFilled() {
            return this.descriptions.reduce(
                (acc, description) =>
                    acc &&
                    description.title["en"] &&
                    description.title["zh-hk"] &&
                    description.title["zh-cn"] &&
                    description.content["en"] &&
                    description.content["zh-hk"] &&
                    description.content["zh-cn"],
                true
            );
        },

        allowSave() {
            let flag = true

            if (this.withImage) {
                flag = flag && (this.images.length > 0 || this.imageFiles.length > 0)
            }

            return flag &&
                this.series &&
                this.category &&
                this.title["en"] &&
                this.title["zh-hk"] &&
                this.title["zh-cn"] &&
                this.subtitle["en"] &&
                this.subtitle["zh-hk"] &&
                this.subtitle["zh-cn"] &&
                this.descriptionsFilled &&
                (this.coverFile || this.cover) &&
                this.prices.length > 0 &&
                this.availableBranches.length > 0
        }
    },
    methods: {
        slotSelected(date, jsEvent, view, resource) {
            console.log(date, jsEvent, view, resource)
        },

        setCover() {
            this.isImageModalsActive.cover = true
        },

        setImage() {
            this.isImageModalsActive.image = true
        },

        setSku(evt, skuIndex) {
            this.skuEditTargetIndex = skuIndex
            this.isSkuModalActive = true
        },

        addDescriptionBlock() {
            this.descriptions.push({
                title: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                content: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                }
            })
        },

        removeDescriptionBlock(targetIndex) {
            this.descriptions.splice(targetIndex, 1)
        },

        removeSKU(targetIndex) {
            this.prices.splice(targetIndex, 1)
        },

        revealDescriptionBlockElement(evt) {
            evt.currentTarget.parentElement.parentElement.parentElement.parentElement.classList.toggle("border-active")
        },

        revealImageElement(evt) {
            evt.currentTarget.parentElement.classList.toggle("active")
        },

        checkRow(a, b) {
            return a._id === b._id
        },

        processTitleEditOperation(operation) {
            this.title[this.lang] = operation.api.origElements.innerHTML
        },

        processSubtitleEditOperation(operation) {
            this.subtitle[this.lang] = operation.api.origElements.innerHTML
        },

        processDescriptionTitleEditOperation(operation) {
            let index = -1
            let temp = operation.api.origElements.parentElement
            while ((temp = temp.previousElementSibling)) {
                index++
            }
            this.descriptions[index]["title"][this.lang] = operation.api.origElements.innerHTML
        },

        processDescriptionContentEditOperation(operation) {
            let index = -1
            let temp = operation.api.origElements.parentElement
            while ((temp = temp.previousElementSibling)) {
                index++
            }
            this.descriptions[index]["content"][this.lang] = operation.api.origElements.innerHTML
        },

        resetTitleColorToDefault() {
            let color = tinycolor({
                r: 113,
                g: 113,
                b: 113,
                a: 1
            })
            this.titleColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        resetShareButtonsColorToDefault() {
            let color = tinycolor({
                r: 113,
                g: 113,
                b: 113,
                a: 1
            })
            this.shareColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        async save() {
            const {
                hidden,
                series,
                category,
                title,
                subtitle,
                descriptions,
                prices,
                availableBranches,
            } = this;

            const temp = {
                hidden,
                series,
                category,
                title,
                subtitle,
                descriptions,
                prices,
                availableBranches,
            };

            if (this.cost) {
                temp.cost[this.costCurrency] = this.cost
            }

            if (this.titleColor.rgba) {
                temp.titleColor = this.titleColor.rgba
            }

            if (this.shareColor.rgba) {
                temp.shareColor = this.shareColor.rgba
            }

            if (this.videoCover) {
                temp.videoCover = this.videoCover
            }

            this.loading = true

            temp.cover = this.coverFile

            if (
                this.withImage === true &&
                this.imageFiles.length > 0
            ) {
                temp.images = this.imageFiles
            }

            if (this.withImage === false) {
                temp.images = []
            }

            try {
                await this.$store.dispatch(
                    "salon/addTreatment",
                    temp
                );
            } catch (e) {
                console.error(e)
                this.loading = false
                this.$buefy.toast.open({
                    message: "Oops! Something's wrong",
                    type: "is-danger"
                })
                return
            }
            this.loading = false
            this.$buefy.toast.open({
                message: "Treatment created",
                type: "is-success"
            })
            this.$router.push({
                name: "Salon-series"
            })
        },

        async update() {
            this.loading = true;

            const temp = {
                _id: this._id,
                hidden: this.hidden,
                series: this.series,
                category: this.category,
                title: this.title,
                subtitle: this.subtitle,
                descriptions: this.descriptions,
                prices: this.prices,
                availableBranches: this.availableBranches
            };

            if (this.cost) {
                temp.cost[this.costCurrency] = this.cost;
            }

            if (this.coverFile) {
                temp.cover = this.coverFile;
            }

            if (
                this.withImage === true &&
                this.imageFiles.length > 0
            ) {
                temp.images = this.imageFiles;
            }

            if (this.withImage === false) {
                temp.images = [];
            }

            if (this.titleColor.rgba) {
                temp.titleColor = this.titleColor.rgba;
            }

            if (this.shareColor.rgba) {
                temp.shareColor = this.shareColor.rgba;
            }

            if (this.videoCover) {
                temp.videoCover = this.videoCover;
            }

            try {
                await this.$store.dispatch(
                    "salon/updateTreatment",
                    temp
                );
            } catch (e) {
                console.error(e);
                this.loading = false;
                this.$buefy.toast.open({
                    message: "Oops! Something's wrong",
                    type: "is-danger"
                });
                return;
            }

            this.loading = false;
            this.$buefy.toast.open({
                message: "Treatment updated",
                type: "is-success"
            });
        }
    },
    mounted() {
        const treatment = this.treatments
            .find(
                ({ _id }) => _id === this.$route.params.treatmentId
            );
        if (treatment) {
            this.mode = "Update"
            this._id = treatment._id
            this.hidden = treatment.hidden
            this.title = treatment.title
            this.subtitle = treatment.subtitle
            this.category = treatment.category
            this.series = treatment.series
            this.descriptions = treatment.descriptions
            this.prices = treatment.prices
            this.availableBranches = treatment.availableBranches

            console.log(treatment.images)
            this.withImage = treatment.images.length > 0
            this.images = treatment.images.map(
                image => `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/treatment/image/${image}`
            )
            this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/treatment/image/${treatment.cover}`
            if (treatment.titleColor) {
                this.titleColor = treatment.titleColor
            }
            if (treatment.shareColor) {
                this.shareColor = treatment.shareColor
            }
            if (treatment.cost) {
                this.costCurrency = Object.keys(treatment.cost)[0]
                this.cost = treatment.cost[this.costCurrency]
            }
            if (treatment.videoCover) {
                this.videoCover = treatment.videoCover
            }
        }

        document.getElementById("app").addEventListener(
            "click",
            (evt) => {
                if (
                    !evt.target.matches(".color-picker *") &&
                    !evt.target.matches(".button-color-picker") &&
                    !evt.target.matches(".button-color-picker *")
                ) {
                    this.isTitleColorModalActive = false
                    this.isShareButtonsColorModalActive = false
                }
            }
        )
    },
    watch: {
        "treatments": {
            handler (newVal) {
                if (newVal.length > 0) {
                    const treatment = newVal.find(
                        treatment => treatment._id === this.$route.params.treatmentId
                    )
                    if (treatment) {
                        this.mode = "Update"
                        this._id = treatment._id
                        this.hidden = treatment.hidden
                        this.title = treatment.title
                        this.subtitle = treatment.subtitle
                        this.category = treatment.category
                        this.series = treatment.series
                        this.descriptions = treatment.descriptions
                        this.prices = treatment.prices
                        this.duration = treatment.duration
                        this.availableBranches = treatment.availableBranches

                        console.log(treatment.images)
                        this.withImage = treatment.images.length > 0
                        this.images = treatment.images.map(
                            image => `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/treatment/image/${image}`
                        )
                        this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/treatment/image/${treatment.cover}`
                        if (treatment.titleColor) {
                            this.titleColor = treatment.titleColor
                        }
                        if (treatment.shareColor) {
                            this.shareColor = treatment.shareColor
                        }
                        if (treatment.cost) {
                            this.costCurrency = Object.keys(treatment.cost)[0]
                            this.cost = treatment.cost[this.costCurrency]
                        }
                        if (treatment.videoCover) {
                            this.videoCover = treatment.videoCover
                        }
                    }
                }
            },
            deep: true
        },

        "coverFile" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader()
                reader.addEventListener(
                    "load",
                    () => {
                        this.cover = reader.result
                    },
                    false
                )
                reader.readAsDataURL(newVal)
            }
        },

        "imageFiles" (newVal) {
            if (
                newVal.length &&
                newVal.length > 0
            ) {
                let temp = []
                newVal.forEach(
                    file => {
                        if (file instanceof File) {
                            const reader = new FileReader()
                            reader.addEventListener(
                                "load",
                                () => {
                                    temp.push(reader.result)
                                },
                                false
                            )
                            reader.readAsDataURL(file)
                        }
                    }
                )
                this.images = temp
            }
        },
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.treatment-details {
    background-color: rgba(255, 255, 255, 1);

    .editable {
        position: relative;
        border-bottom: dashed 2px #8c67ef;

        &:after {
            position: absolute;
            bottom: -24px;
            right: 0;
            content: "Click to edit";
            font-size: 14px;
            font-weight: normal;
            padding: 3px;
        }
    }

    button {
        &.edit {
            position: absolute;
            z-index: 8;
        }
    }

    .border-active {
        border: dashed 2px #ff1f4b;
    }

    .banner {
        position: relative;
        padding-top: 62px;
        min-height: 40vh;

        &.active {
            border: dashed 2px #ff1f4b;
        }

        & > button.edit {
            bottom: 5px;
            right: 5px;
        }

        & > .categories {
            position: absolute;
            top: 0;
            left: 5px;
            right: 50%;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-top: 5px;

            z-index: 8;

            & > .separator {
                margin: 0 15px;
                font-size: 20px;
                font-weight: bold;
            }
        }

        & > .treatment-mode {
            position: absolute;
            top: 0;
            left: 50%;
            right: 5px;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            margin-top: 5px;

            z-index: 8;

            .control {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 4px;

                a.button {
                    &.is-selected.is-dark {
                        color: rgba(255, 255, 255, 1)
                    }
                }
            }

            & > .separator {
                margin: 0 15px;
                font-size: 20px;
                font-weight: bold;
            }
        }

        .img-responsive {
            height: auto;
            max-height: 400px;
            max-width: 100%;
        }

        .treatment-image {
            &.active {
                border: dashed 2px #ff1f4b;
            }

            & > button.edit {
                top: 0;
                right: 0;
            }

            & > .mirror {
                top: 100%;
                left: 0;
                right: 0;

                height: 100%;
                width: inherit;
                margin: 0 auto;

                transform: scaleY(-1);
                -webkit-mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
            }
        }
    }

    .social-network {
        color: rgba(113, 113, 113, 1);
        font-size: 28px;
        margin-bottom: 7.5px;
    }

    .color-picker {
        display: none;
        top: 0;
        left: 0;
        z-index: 10;

        &.show {
            display: block;
        }
    }

    .button-color-default {
        text-align: center;
        padding: 0.5rem;
        padding-top: 0;
    }
}
</style>
