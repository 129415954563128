<template>
    <div class="card sku position-relative">
        <div class="btn-delete position-absolute"
             v-if="deletable"
             v-on:click="removeSKU">
            <font-awesome-icon icon="minus-circle"></font-awesome-icon>
        </div>

        <div class="card-body d-flex flex-column justify-content-center align-items-center"
             v-on:click="isEditSkuModalActive = true">
            <h5 class="text-center mb-1">
                {{ sku.name[lang] }}
            </h5>
            <p class="text-center m-0">
                HK${{ sku.hkd | currency("", 0) }}
            </p>
            <p class="text-center m-0"
               v-if="sku.duration">
                {{ sku.duration.HH }}:{{ sku.duration.mm }}
            </p>
        </div>

        <modal-set-sku editing
                       v-bind:active.sync="isEditSkuModalActive"
                       v-bind:sku.sync="sku"
                       v-on:change="editSku">
        </modal-set-sku>
    </div>
</template>

<script>
    import ModalSetSku from '../modals/ModalSetSku'

    export default {
        name: 'SkuCard',
        components: {
            ModalSetSku
        },
        props: {
            'lang': {
                type: String,
                required: true
            },

            'deletable': {
                type: Boolean,
                default: true
            },

            'sku': {
                'type': Object
            }
        },
        data() {
            return {
                isEditSkuModalActive: false
            }
        },
        computed: {},
        methods: {
            editSku() {
                this.isEditSkuModalActive = false
                this.$emit('edit', this.sku)
            },

            removeSKU() {
                this.$emit('remove')
            }
        }
    }
</script>

<style lang="scss" scoped>
    $theme-colors: (
        "dark": rgba(113, 113, 113, 1)
    );
    @import "~bootstrap/scss/bootstrap";

    .sku {
        margin-right: 15px;
        min-width: 300px;
        max-width: 350px;
        cursor: pointer;

        .btn-delete {
            top: -5px;
            right: -5px;
            height: 24px;
            width: 24px;
            color: #ff3860;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(255, 255, 255, 1);
            border-radius: 50%;

            cursor: pointer;

            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
